:root {
  --primary-color: #C93624;
  --background-color: #F5F9F6;
  --custom-ligth-gray: #efefef;
  --custom-gray: #cecece;
  --custom-dark-gray: #565656;
  --custom-dark-translucent-gray: #56565644;
}

body {
  background-color: #F5F9F6;
  font: normal 0.9em/1.5 Arial,Helvetica,sans-serif;
  max-width: none !important;
}

.test-logo-container {
  position: absolute;
  top: 100px;
  right: 100px;
}

.test-logo {
  width: 150px;
}

.watermark-container {
  position: fixed;
  top: 30px;
  right: 50px;
}

.watermark {
  font-size: 4rem;
  font-weight: 900;
  color: var(--custom-dark-translucent-gray);
}

